const theme = {
    color: {
        black: "#000",
        white: "#fff",
        lightGray: "#D2DCE6",
        blue: "#0E6DFB",
        gray: "#F5F6F8",
        textGray: "#73747D"
    },
    fontSizes: {
        xlarge: "48px",
        large: "40px",
        medium: "28px",
        small: "24px",
        xsmall: "18px"
    },
    spacing: {
        large: "160px",
        lmedium: "128px",
        medium: "80px",
        small: "48px",
        lsmall: "40px",
        msmall: "32px",
        xsmall: "24px",
    },
    breakpoints: {
        pad: "1024px",
        fold: "920px",
        forInform: "860px",
        forBody2: "1120px",
        forBody4: "700px",
        forBody3: "550px",
        mobile: "430px",
    },
    fontWeights: {
        light: 200,
        regular: 400,
        bold: 800,
        extraBold: 900,
    },
    widths: {
        container: "1024px",
        fullWidth: "100%",
        mobileWidth: "90%",
    },
}

export default theme;