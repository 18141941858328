import styled from "styled-components"

const Frame = styled.div`
    display: flex;
    padding: ${props => props.theme.spacing.large} 8px;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.lmedium};
    align-self: stretch;
    border-bottom: ${props => props.theme.color.lightGray};
    background: ${props => props.theme.color.gray};
    overflow: hidden;
    @media (max-width: ${props => props.theme.breakpoints.pad}) {
        padding: 160px var(--numberic-value-value-40, 40px);
    }

    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        padding: 80px var(--numberic-value-value-40, 40px);
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        padding: var(--numberic-value-value-48, 48px) var(--numberic-value-value-24, 24px);
        align-items: flex-start;
        gap: var(--numberic-value-value-72, 72px);
    }
`
const MainFrame = styled.div`
    display: flex;
    width: ${props => props.theme.widths.container};
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: ${props => props.theme.breakpoints.pad}) {
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: ${props => props.theme.widths.fullWidth};
    }
`
const InnerFrame1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--numberic-value-value-24, 24px);
`
const InnerFrame3 = styled.div`
    display: flex;
    width: ${props => props.theme.widths.container};
    justify-content: space-between;
    align-items: center;
    margin-top: 160px;

    @media (max-width: ${props => props.theme.breakpoints.pad}) {
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: ${props => props.theme.widths.fullWidth};
        img {
            content: url(/img/flow-mobile.svg);
        }
        margin-top: 70px;
    }
`
const Text1 = styled.span`
    align-self: stretch;
    color: ${props => props.theme.color.black};
    font-family: Pretendard;
    font-size: ${props => props.theme.fontSizes.xlarge};
    font-style: normal;
    font-weight: ${props => props.theme.fontWeights.bold};
    line-height: 100%; /* 48px */

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        font-size: ${props => props.theme.fontSizes.medium};
    }
`
const Text2 = styled.span`
    align-self: stretch;
    color: ${props => props.theme.color.textGray};
    font-family: Pretendard;
    font-size: ${props => props.theme.fontSizes.small};
    font-style: normal;
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 150%; /* 36px */

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        font-size: 18px;
        padding-top: 80px;
    }
`
const LogoContainer1 = styled.div`
    display:flex;
    margin-right: -100px;
    width: ${props => props.theme.widths.container};
    overflow: visible;
    padding-left: 45px;
    display: none;

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
    position: relative;
        width: ${props => props.theme.widths.mobileWidth};
        display: inline;
        margin: auto;
    }
`
const LogoContainer2 = styled.div`
    position: relative;
    display:flex;
    margin-right: -100px;
    width: ${props => props.theme.widths.container};
    overflow: visible;
    padding-left: 45px;
    margin-top: 40px;
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        display: none;
    }
`
const Icon = styled.img`
    position: absolute;
    left: ${({ idx }) => idx * 74}px; 
    z-index: ${({ totalicons, idx }) => totalicons - idx};  
    object-fit: contain;
    border-radius: 50%;
    filter: drop-shadow(12.8px 0px 12.8px rgba(0, 0, 0, 0.25));

    @media (max-width: ${props => props.theme.breakpoints.forBody4}) {
        width: 8%;
        left: ${({ idx }) => idx * 60}px;
    }

    @media (max-width: ${props => props.theme.breakpoints.forBody3}) {
        width: 6%;
        left: ${({ idx }) => idx * 50}px;
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: 13%;
        left: ${({ idx }) => idx * 35}px;
    }
`
const FlowImg = styled.img`
    width: 100%;
    margin: auto;
    @media (max-width: ${props => props.theme.breakpoints.fold}) {
            width: ${props => props.theme.widths.fullWidth};
        }

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        margin: 0 auto;
    }
`
export default function Body3() {

    const IconList = [{
        name: '삼성증권',
        img: 'icon1.svg'
    },
    {
        name: '한국투자증권',
        img: 'icon2.svg'
    },
    {
        name: 'KB증권',
        img: 'icon3.svg'
    },
    {
        name: 'NH투자증권',
        img: 'icon4.svg'
    },
    {
        name: '미래에셋증권',
        img: 'icon5.svg'
    },
    {
        name: '하나투자',
        img: 'icon6.svg'
    },
    {
        name: '대신투자',
        img: 'icon7.svg'
    },
    {
        name: '키움증권',
        img: 'icon8.svg'
    },
    ]
    return (
        <>
            <Frame>
                <MainFrame>
                    <InnerFrame1>
                        <Text1>공모주<br />청약에서 매도까지 </Text1>
                        <LogoContainer1>
                            {IconList.map((item, idx) => <Icon key={idx} src={`/img/${item.img}`} totalicons={IconList.length} idx={idx} />)}
                        </LogoContainer1>
                        <Text2>공모주 정보를 확인하기 위해 여기저기 사이트를 돌아다녀 보신 적이 있으신가요?<br />이젠 그러실 필요 없이 일육공 하나로 가능합니다.<br />공모주 일정부터 실시간 경쟁률, 자동 청약과 자동 매도까지<br /> 공모주 투자의 모든 것을 한곳에 담았습니다.</Text2>
                    </InnerFrame1>
                    <LogoContainer2>
                        {IconList.map((item, idx) => <Icon key={idx} src={`/img/${item.img}`} totalicons={IconList.length} idx={idx} />)}
                    </LogoContainer2>
                    <InnerFrame3>
                        <FlowImg src="/img/group.svg"></FlowImg>
                    </InnerFrame3>
                </MainFrame>
            </Frame>
        </>
    )
}