import styled from "styled-components";

const Frame = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.large} 8px;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing.lmedium};
  align-self: stretch;
  border-bottom: 1px solid ${props => props.theme.color.lightGray};

  @media (max-width: ${props => props.theme.breakpoints.pad}) {
    padding: 160px var(--numberic-value-value-40, 40px);
  }

  @media (max-width: ${props => props.theme.breakpoints.fold}) {
    padding: 80px var(--numberic-value-value-40, 40px);
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: var(--numberic-value-value-48, 48px) var(--numberic-value-value-24, 24px);
    align-items: flex-start;
    gap: var(--numberic-value-value-72, 72px);
  }
`
const MainFrame = styled.div`
  display: flex;
  width: ${props => props.theme.widths.container};
  flex-direction: column;
  align-items: flex-start;
  
  gap: ${props => props.theme.spacing.medium};

  @media (max-width: ${props => props.theme.breakpoints.pad}) {
    width: ${props => props.theme.widths.fullWidth};
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    width: ${props => props.theme.widths.fullWidth};
    gap: 50px;
  }
`
const InnerFrame1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--numberic-value-value-24, 24px);
  align-self: stretch;
`
const Text1 = styled.span`
  align-self: stretch;
  color: ${props => props.theme.color.black};
  font-family: Pretendard;
  font-size: ${props => props.theme.fontSizes.xlarge};
  font-style: normal;
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 100%; /* 48px */

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 28px;
  }
`
const Text2 = styled.span`
  align-self: stretch;
  color: ${props => props.theme.color.textGray};
  font-family: Pretendard;
  font-size: ${props => props.theme.fontSizes.small};
  font-style: normal;
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 150%; /* 36px */

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: ${props => props.theme.fontSizes.xsmall};
  }
`
const InnerFrame2 = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.pad}) {
    width: ${props => props.theme.widths.fullWidth};
  }
  @media (max-width: ${props => props.theme.breakpoints.forBody2}) {
    width: ${props => props.theme.widths.fullWidth};
  }
`
const Text3 = styled.span`
  color: #000;
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 48px */

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: ${props => props.theme.fontSizes.xsmall};
  }
`
const ImageFrame1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--numberic-value-value-24, 24px);
  align-self: stretch;
  padding-top: 30px;

   @media (max-width: ${props => props.theme.breakpoints.pad}) {
    width: ${props => props.theme.widths.fullWidth};
    content: url(/img/graph_pad.svg);
  }

  @media (max-width: ${props => props.theme.breakpoints.forBody2}) {
    width: ${props => props.theme.widths.fullWidth};
    content: url(/img/graph_fold.svg);
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    content: url(/img/graph_mobile.svg);
  }
`
const ImageFrame2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--numberic-value-value-24, 24px);
  align-self: stretch;
  padding-top: 30px;
  overflow: auto;
`
const GraphImg = styled.img`
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    content: url(/img/table-small.svg);
  }
`
const SourceFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--numberic-value-value-8, 8px);
  align-self: stretch;
`
const ImageSource = styled.span`
  color: #000;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: ${props => props.theme.fontWeights.regular};
  padding-top: 20px;

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 14px;
  }
`
const InnerFrame = styled.div`
    display: flex;
    width: ${props => props.theme.widths.container};
    flex-direction: column;
    align-items: flex-start;
    gap: var(--numberic-value-value-8, 8px);
    align-self: stretch;

    @media (max-width: ${props => props.theme.breakpoints.pad}) {
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: ${props => props.theme.widths.fullWidth};
    }
`
const Text4 = styled.span`
    color: var(--ui-color-text-lite, #999BA9);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
`
const Text5 = styled.span`
    color: var(--ui-color-text-lite, #999BA9);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;
`
const Ol = styled.ol`
  padding-left: 12px;
  padding-top: 0px;
`
const Text6 = styled.span`
  color: #414351;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
`

export default function Body2() {
  return (
    <>
      <Frame>
        <MainFrame>
          <InnerFrame1>
            <Text1>우량 공모주를 선별하여</Text1>
            <Text2>공모주라도 다 같은 공모주가 아닙니다.<br />일육공은 정량적 데이터를 기반으로 공모주 매력지수를 산출하고, <br />우량 공모주를 선별합니다.</Text2>
          </InnerFrame1>
          <InnerFrame2>
            <Text3>일육공 투자성과(메타로고스 IPO with 매크로핀)</Text3>
            <ImageFrame1>
              <img src="/img/graph_pc.svg"></img>
            </ImageFrame1>
            <ImageFrame2>
              <GraphImg src="/img/table_pc.svg"></GraphImg>
            </ImageFrame2>
            <SourceFrame>
              <ImageSource>출처: 코스콤 로보어드바이저 테스트베드 공시자료</ImageSource>
              <Text6>운용기준일: 2024년 10월 31일</Text6>
            </SourceFrame>
          </InnerFrame2>
          <InnerFrame>
            <Text4>로보어드바이저 관련 유의사항</Text4>
            <Text5>
              <Ol>
                <li>로보어드바이저가  고객에게  맞는  투자  또는  수익  달성을  보장하지 않습니다.</li>
                <li>로보어드바이저  테스트베드  심의결과가  해당  알고리즘의  품질이나 수익성을 보장하지 않습니다.</li>
                <li>고객의  투자일임에  사용되는  로보어드바이저  알고리즘은  테스트베드 운용에 사용되는 알고리즘과 동일하나, 고객 계좌별 수익률은 계약자의 옵션선택,  운용  개시시점  및  주문  체결시점  등에  따라  테스트베드 공시 수익률과는 일부 차이가 있을 수 있습니다.</li>
                <li>테스트베드  운용성과는  참여사가  알고리즘의  투자조언에  따라  직접 운용하는  계좌의  운용성과이며,  과거의  성과가  미래수익을  보장하지 않습니다.</li>
              </Ol>
            </Text5>
          </InnerFrame>
        </MainFrame>
      </Frame>
    </>
  )
}