import styled from "styled-components"
import QR from "./Qr"

const Frame = styled.div`
    display: inline-flex;
    padding: var(--numberic-value-value-24, 24px);
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--numberic-value-value-24, 24px);
    border: 1px solid #B2B2B2;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%), linear-gradient(180deg, #FEFEFE 0%, #EAEBF2 100%);
    box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.15);
    position: fixed;
    right: 24px;
    bottom: 24px;
    z-index: 50000;
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        display: none;
    }
`
const MainFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`
const IconImg = styled.img`
    width: 64px;
    height: 64px;
`
const InnerFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--numberic-value-value-4, 4px);
`
const Text1 = styled.span`
    color: ${props => props.theme.color.black};
    font-family: Pretendard;
    font-size: ${props => props.theme.fontSizes.xsmall};
    font-style: normal;
    font-weight: ${props => props.theme.fontWeights.extraBold};
    line-height: 100%; /* 18px */
`
const InnerFrame2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
`
const IconImg2 = styled.img`
    width: var(--numberic-value-value-24, 24px);
    height: var(--numberic-value-value-24, 24px);
`
export default function FloatingButton() {
    return (
        <>
            <Frame>
                <MainFrame>
                    <IconImg src="/img/160.svg"></IconImg>
                    <InnerFrame>
                        <Text1>모바일앱 설치</Text1>
                        <InnerFrame2>
                            <IconImg2 src="/img/apple.svg" style={{ cursor: 'pointer' }} onClick={() => window.open("https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484")}></IconImg2>
                            <IconImg2 src="/img/android.svg" style={{ cursor: 'pointer' }} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.qniverse.ipo")}></IconImg2>
                        </InnerFrame2>
                    </InnerFrame>
                    <QR />
                </MainFrame>
            </Frame>
        </>
    )
}