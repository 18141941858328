import Header from './components/Header.js';
import Body from './components/Body.js';
import Body2 from './components/Body2.js';
import Body3 from './components/Body3.js';
import Body4 from './components/Body4.js';
import Note from './components/Note.js';
import Footer from './components/Footer.js';
import FloatingButton from './components/FloatingButton.js';
import GlobalStyles from './style/GlobalStyles.js';
import { ThemeProvider } from 'styled-components';
import theme from './style/theme.js';

function App() {
  return (
    <><ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header />
      <Body />
      <Body2 />
      <Body3 />
      <Body4 />
      <Note />
      <Footer />
      <FloatingButton />
    </ThemeProvider>
    </>
  );
}

export default App;
