import styled from "styled-components";

const HeaderDiv = styled.div`
  width: ${props => props.theme.widths.fullWidth};
  height: 724px;
  top: 0;
  background: url(/img/main.jpeg) lightgray 50% / cover no-repeat;

  @media (max-width: ${props => props.theme.breakpoints.fold}){
    height: 514px;
  }
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    height: 321px;
  }
`
const Navigation = styled.div`
  width: ${props => props.theme.widths.fullWidth};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.black};
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  z-index: 100;
`
const Logo2 = styled.div`
  color: ${props => props.theme.color.black};
  font-family: Pretendard;
  font-size: ${props => props.theme.fontSizes.xsmall};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 32px var(--numberic-value-value-24, 24px);
`
const HeaderContent = styled.div`
  text-align: center;
  font-family: Pretendard;
  font-size: 80px;
  font-style: normal;
  font-weight: ${props => props.theme.fontWeights.extraBold};
  line-height: 100%; /* 80px */
  padding-top: 362px;
  
  @media (max-width: ${props => props.theme.breakpoints.fold}) {
      font-size: 64px;
      margin: auto;
      padding-top: 262px;
    }
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      font-size: 40px;
      margin: auto;
      padding-top: 162px;
    }
`
const Img = styled.img`
  width: 150px;
  padding: 32px var(--numberic-value-value-24, 24px);
`
export default function Header() {
  return (
    <>
      <HeaderDiv>
        <Navigation>
          <Img src="/img/main-logo.svg"></Img>
          <Logo2 style={{ cursor: 'pointer' }} onClick={() => window.open("https://160ipo.com/")}>160 IPO↗</Logo2>
        </Navigation>
        <HeaderContent>함께 만드는 금융</HeaderContent>
      </HeaderDiv>
    </>
  )
}