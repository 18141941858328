import styled from "styled-components";

const Frame = styled.div`
    display: flex;
    padding: ${props => props.theme.spacing.large} 8px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    @media (max-width: ${props => props.theme.breakpoints.pad}){
        padding: 160px var(--numberic-value-value-40, 40px);
    }

    @media (max-width: ${props => props.theme.breakpoints.fold}){
        font-size: padding: 80px var(--numberic-value-value-40, 40px) 160px var(--numberic-value-value-40, 40px);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        padding: var(--numberic-value-value-48, 48px) var(--numberic-value-value-24, 24px);
        align-items: flex-start;
        border: 0px solid ${props => props.theme.color.lightGray};
    }
`
const MainFrame = styled.div`
    display: flex;
    width: ${props => props.theme.widths.container};
    flex-direction: column;
    align-items: center;
    gap: 170px;

    @media (max-width: ${props => props.theme.breakpoints.pad}){
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.fold}){
       width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        width: ${props => props.theme.widths.fullWidth};
        lign-items: flex-start;
        gap: 0;
    }
`
const InformFrame = styled.div`
    display: flex;
    width: ${props => props.theme.widths.container};
    align-items: flex-start;
    gap: var(--numberic-value-value-24, 24px);

    @media (max-width: ${props => props.theme.breakpoints.pad}){
        width: ${props => props.theme.widths.fullWidth};
        gap: 15px;
    }
    @media (max-width: ${props => props.theme.breakpoints.forInform}){
        width: ${props => props.theme.widths.fullWidth};
        flex-direction: column;
        gap: 0;
        border-radius: 16px;
        border: 2px solid #DDE0ED;
    }
`
const Title = styled.span`
    color: ${props => props.theme.color.black};
    font-family: Pretendard;
    font-size: ${props => props.theme.fontSizes.xlarge};
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-align: center;

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        font-size: 28px;  
        line-height: 150%;    
    }
`
const PeopleFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: var(--numberic-value-value-72, 72px) var(--numberic-value-value-40, 40px);
    align-self: stretch;
    flex-wrap: wrap;
    margin-bottom: 128px;

    @media (max-width: ${props => props.theme.breakpoints.pad}){
        gap: 72px var(--numberic-value-value-40, 40px);
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        gap: 0;
    }
`

const PersonFrame = styled.div`
    display: flex;
    width: 303.667px;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        width: ${props => props.theme.widths.fullWidth};
        flex-direction: column; 
    }
`
const PersonImg = styled.img`
    width: 60%;
    display: flex;
    padding-top: 10px;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 130px;
    background: #D0D0D0;
    box-shadow: 0px 16.067px 16.067px 0px rgba(0, 0, 0, 0.15);
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: 50%;
        content: url(${props => `/img/${props.mobilesrc}`});
        margin-top: 30px;
    }
`
const ImgSection = styled.div`
    display: flex;
    padding-top: 25px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
`
const NameSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    padding-bottom: 25px;
`
const Inform = styled.span`
    margin: auto;
    align-self: stretch;
    color: #505050;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 150%; /* 24px */

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        text-align: center;
    }
`
const Name = styled.span`
    color: ${props => props.theme.color.black};
    font-family: Pretendard;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`
const InformInnerFrame = styled.div`
    display: flex;
    height: 313px;
    padding: var(--numberic-value-value-24, 24px);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 16px;
    border: 2px solid #DDE0ED;

    @media (max-width: ${props => props.theme.breakpoints.fold}){
        height: 250px;
    }

    @media (max-width: ${props => props.theme.breakpoints.forInform}){
        flex-direction: row; 
        margin: auto;
        width: ${props => props.theme.widths.fullWidth};
        border: none;
        padding: 20px 0;
        border-radius: 0;
        border-bottom: 1px solid #DDE0ED;
    }
`
const IconImg = styled.img`
    width: 82px;
    height: 82px;
    flex-shrink: 0;

    @media (max-width: ${props => props.theme.breakpoints.forInform}){
        width: var(--numberic-value-value-40, 40px);
        height: var(--numberic-value-value-40, 40px);
        margin: auto 0;
        padding-left: 20px;
    }
`
const IconInform = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: ${props => props.theme.breakpoints.forInform}){
        align-items: flex-end;
    }
`
const InformText1 = styled.span`
    color: ${props => props.theme.color.black};
    font-family: Pretendard;
    font-size: ${props => props.theme.fontSizes.xsmall};
    font-style: normal;
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: 120%; /* 28.8px */

    @media (max-width: ${props => props.theme.breakpoints.forInform}){
        font-size: 18px;
        padding-right: 20px;
    }
`
const InformText1_1 = styled(InformText1)`
    padding-bottom: 16px;
`
const InformText2 = styled.span`
    color: ${props => props.theme.color.black};
    font-family: Pretendard;
    font-size: ${props => props.theme.fontSizes.large};
    font-style: normal;
    font-weight: ${props => props.theme.fontWeights.extraBold};
    line-height: 120%; /* 48px */

    @media (max-width: ${props => props.theme.breakpoints.fold}){
        font-size: 30px;
    }

    @media (max-width: ${props => props.theme.breakpoints.forInform}){
        font-size: ${props => props.theme.fontSizes.msmall};
        padding-right: 20px;
    }
`
const InformInnerFrame2 = styled.div`
    display: flex;
    height: 313px;
    padding: var(--numberic-value-value-24, 24px);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 16px;
    border: 2px solid #DDE0ED;

    @media (max-width: ${props => props.theme.breakpoints.fold}){
        height: 250px;
    }

    @media (max-width: ${props => props.theme.breakpoints.forInform}){
        flex-direction: row; 
        margin: auto;
        width: ${props => props.theme.widths.fullWidth};
        border: none;
        padding: 20px 0;
    }
`
const InformSource = styled.span`
    color: #414351;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    width: ${props => props.theme.widths.container};
    text-align: right;

    @media (max-width: ${props => props.theme.breakpoints.pad}){
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.fold}){
       width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        width: ${props => props.theme.widths.fullWidth};
    }
`
const BottomFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--numberic-value-value-16, 16px);
    margin-top: 50px;

    @media (max-width: ${props => props.theme.breakpoints.pad}){
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.fold}){
       width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        width: ${props => props.theme.widths.fullWidth};
        margin-bottom: 50px;
    }
`
const SubTitle = styled.span`
    color: ${props => props.theme.color.black};
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    align-self: stretch;

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        font-size: 20px;
    }
`
const SmallText = styled.span`
    color: var(--ui-color-text-dark, #555564);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    padding-top: 4px;
    
    @media (max-width: ${props => props.theme.breakpoints.forInform}){
        font-size: ${props => props.theme.fontSizes.msmall};
        padding-right: 20px;
    }
`

export default function Body5() {
    const PeopleList = [{
        img: 'p1.png',
        name: '이동훈 대표',
        inform: ['성균관대학교 경영대학 겸임교수', '대신증권 운용총괄임원/경영기획본부장', '하나은행/대우증권 FICC 운용', '성균관대학교 경영학 박사(재무전공)', 'Georgia Tech. 항공우주공학 박사', '부산대학교 항공우주공학과']
    },
    {
        img: 'p2.png',
        name: '박재원 대표',
        inform: ['한양대학교 경제금융학부 겸임교수', '한국거래소 파생상품발전위원회 위원', '대신증권 주식/주식파생운용 ', 'KAIST 경영공학 석사/박사(재무전공)', '서울대학교 재료공학부']
    },
    // {
    //     img: 'p3.png',
    //     name: '윤도선 사업회사 대표',
    //     inform: ['KIS 채권평가 마케팅 본부장', 'KGI 증권 기업금융부', '동국대학교 경제학과']
    // },
    // {
    //     img: 'p4.png',
    //     name: '정재룡 준법 · 리스크관리 ',
    //     inform: ['금융감독원 자산운용/자본시장/', '불공정거래/금융안정위원회', '핀테크지원센터 심사위원', '미시건 주립대학 Finance 석사', '서강대 금융경제학과 석사']
    // },
    // {
    //     img: 'p5.png',
    //     name: '김민기 CTO',
    //     inform: ['KB/NH증권 주식파생운용/퀀트', 'LG CNS 시스템 엔지니어', 'KAIST 금융 MBA', '서울대학교 자원공학과']
    // },
    {
        img: 'p6.png',
        name: '이경륜 Quant · 테크',
        inform: ['NAVER 검색모델링팀', '삼성전자 영상디스플레이 사업부', '연세대학교 로스쿨 석사/변호사', '서울대학교 컴퓨터공학 석사', '서울대학교 전산학과']
    },
        // {
        //     img: 'p7.png',
        //     name: '우창수 Quant · 운용',
        //     inform: ['KB증권 Trading Quant부', 'KIS채권평가 금리파생팀', 'KAIST 수리과학 학사/석사']
        // }
    ]

    return (
        <>
            <Frame>
                <MainFrame>
                    <Title><b>금융 + 퀀트 + 테크</b><br /> 프로들과 함께 합니다</Title>
                    <PeopleFrame>
                        {PeopleList.map((person, index) => {
                            return (
                                <PersonFrame key={index}>
                                    <ImgSection>
                                        <PersonImg
                                            src={`/img/${person.img}`}
                                            mobilesrc={`p2_${index + 1}.png`}
                                            alt={person.name}
                                        />
                                    </ImgSection>
                                    <NameSection>
                                        <Name>{person.name}</Name>
                                    </NameSection>
                                    <Inform>
                                        {person.inform.length > 0 ? (
                                            person.inform.map((info, i) => (
                                                <span key={i}>
                                                    {info}
                                                    <br />
                                                </span>
                                            ))
                                        ) : (
                                            <span>정보 없음</span>
                                        )}
                                    </Inform>
                                </PersonFrame>
                            );
                        })}
                    </PeopleFrame>
                </MainFrame>
                <BottomFrame>
                    <SubTitle>160 공모주 앱</SubTitle>
                    <InformFrame>
                        <InformInnerFrame>
                            <IconImg src="/img/icon2_1.svg"></IconImg>
                            <IconInform>
                                <InformText1_1>누적 다운로드</InformText1_1>
                                <InformText2>42,000건</InformText2>
                            </IconInform>
                        </InformInnerFrame>
                        <InformInnerFrame>
                            <IconImg src="/img/icon2_2.svg"></IconImg>
                            <IconInform>
                                <InformText1_1>회원 수</InformText1_1>
                                <InformText2>26,000명</InformText2>
                            </IconInform>
                        </InformInnerFrame>
                        <InformInnerFrame2>
                            <IconImg src="/img/icon2_3.svg"></IconImg>
                            <IconInform>
                                <InformText1>월간 활성 사용자</InformText1>
                                <SmallText>* 제휴 포함</SmallText>
                                <InformText2>389,000명</InformText2>
                            </IconInform>
                        </InformInnerFrame2>
                    </InformFrame>
                    <InformSource>출처: 메타로고스(주) | 기준일: 2024년 11월 1일</InformSource>
                </BottomFrame>
            </Frame >
        </>
    )
}