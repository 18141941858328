import styled from "styled-components"

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-top: 1px solid ${props => props.theme.color.black};

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        border: none;
        align-items: center;
        gap: var(--numberic-value-value-24, 24px);
    }
`
const FirstFrame = styled.div`
    display: flex;
    padding: 24px 40px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: center;
    border-bottom: 1px solid #D8D8D8;

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        width: ${props => props.theme.widths.fullWidth};
        margin: auto;
        padding: 20px 0;
        border-top: 1px solid ${props => props.theme.color.black};
    }
`
const SecondFrame = styled.div`
    display: flex;
    padding: 24px 40px;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        width: ${props => props.theme.widths.fullWidth};
        margin: auto;
        padding: 20px 0;
    }
`
const FirstInnerFrame = styled.div`
    display: flex;
    width: ${props => props.theme.widths.container};
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${props => props.theme.breakpoints.pad}){
        width: ${props => props.theme.widths.fullWidth};
    }
    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        width: ${props => props.theme.widths.mobileWidth};
    }
`
const FirstLeftFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`
const TopFrame = styled.div`
    width: 80%;
`
const BottomFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--numberic-value-value-12, 12px);
`
const Text2 = styled.span`
    color: ${props => props.theme.color.black};
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: ${props => props.theme.fontWeights.regular};
    line-height: normal;

    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        order: 2;
        width: ${props => props.theme.widths.fullWidth};
        font-size: 14px;
    }
`
const SecondInnerFrame = styled.div`
    display: flex;
    width: ${props => props.theme.widths.container};
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: ${props => props.theme.breakpoints.pad}){
        width: ${props => props.theme.widths.fullWidth};
    }
    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        width: ${props => props.theme.widths.mobileWidth};
    }
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        width: ${props => props.theme.widths.fullWidth};
        flex-direction: column;
        gap: 20px;
        margin-bottom: 150px;
    }
`
const ButtonFrame = styled.div`
    display: flex;
    width: ${props => props.theme.widths.fullWidth};
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    position: fixed;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 100;
`
const Button = styled.button`
    display: none;
    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        display: inline;
        width: ${props => props.theme.widths.mobileWidth};
        height: 60px;
        margin: auto;
        border-radius: 18px;
        background: var(--brand-color-2-0, ${props => props.theme.color.blue});
        color:  ${props => props.theme.color.white};
        text-align: center;
        font-family: Pretendard;
        font-size:  ${props => props.theme.fontSizes.xsmall};
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 27px */
        border: none;
        margin: 20px;
    }
`
const Img = styled.img`
    width: 128.308px;
    height: var(--numberic-value-value-24, 24px);
`
export default function Footer() {
    return (
        <>
            <Frame>
                <FirstFrame>
                    <FirstInnerFrame>
                        <FirstLeftFrame>
                            <TopFrame><Img src="/img/main-logo.svg"></Img></TopFrame>
                            <BottomFrame>
                                <Text2>사업자등록번호 : 562-81-01980</Text2>
                                <Text2>서울특별시 강남구 역삼로17길 10 부강빌딩 3층</Text2>
                            </BottomFrame>
                        </FirstLeftFrame>
                    </FirstInnerFrame>
                </FirstFrame>
                <SecondFrame>
                    <SecondInnerFrame>
                        <Text2 style={{ textAlign: 'center' }}>© metaLogos, Inc. All Rights Reserved.</Text2>
                    </SecondInnerFrame>
                </SecondFrame>
                <ButtonFrame>
                    <Button style={{ cursor: 'pointer' }} onClick={() => window.open("https://qr-efbc6.web.app")}>일육공 다운로드</Button>
                </ButtonFrame>
            </Frame >
        </>
    )
}