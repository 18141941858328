import styled from "styled-components"

const Frame = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.large} 8px;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing.lmedium};
  align-self: stretch;
  padding: var(--numberic-value-value-40, 40px) var(--numberic-value-value-8, 8px);
  background-color: ${props => props.theme.color.gray};
  }
`
const MainFrame = styled.div`
    display: flex;
    width: ${props => props.theme.widths.container};
    flex-direction: column;
    align-items: flex-start;
    gap: var(--numberic-value-value-40, 40px);

    @media (max-width: ${props => props.theme.breakpoints.pad}) {
        width: ${props => props.theme.widths.mobileWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        width: ${props => props.theme.widths.mobileWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: ${props => props.theme.widths.mobileWidth};
    }
`
const InnerFrame = styled.div`
    display: flex;
    width: ${props => props.theme.widths.container};
    flex-direction: column;
    align-items: flex-start;
    gap: var(--numberic-value-value-8, 8px);
    align-self: stretch;

    @media (max-width: ${props => props.theme.breakpoints.pad}) {
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.fold}) {
        width: ${props => props.theme.widths.fullWidth};
    }

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
        width: ${props => props.theme.widths.fullWidth};
    }
`
const Text1 = styled.span`
    color: var(--ui-color-text-lite, #999BA9);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
`
const Text2 = styled.span`
    color: var(--ui-color-text-lite, #999BA9);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;
`
const Ol = styled.ol`
    padding-left: 12px;
    padding-top: 0px;
`

export default function Note() {
    return (
        <>
            <Frame>
                <MainFrame>
                    <InnerFrame>
                        <Text1>매력지수 관련 유의사항</Text1>
                        <Text2>
                            <Ol>
                                <li>당사의  매력지수  제공이  특정  공모주에  대한  투자권유가  아니며, 매력지수가  높다고  반드시  투자원금이나  투자수익이  보장되지  않습니다.</li>
                                <li>당사의  매력지수는  특정  공모주  및  그  발행회사에  대한  신뢰할  수 있다고  판단되는  공시  자료  등을  바탕으로  작성되었으나  투자자의 투자 결정을 위한 참고로 제공되는 것이며 최종적인 투자판단은 투자자에게 있습니다. </li>
                            </Ol>
                        </Text2>
                    </InnerFrame>
                    <InnerFrame>
                        <Text1>투자자 유의사항</Text1>
                        <Text2>
                            <Ol>
                                <li>투자자는  본  서비스에  대하여  당사로부터  충분한  설명을  받을  권리가 있으며,  당사와  투자일임계약을  체결하기  전에  투자일임계약  권유문서 및 투자일임계약서를 반드시 읽어 보시기 바랍니다. </li>
                                <li>공모주 및 투자일임계약은 예금자보호법에 따라 보호되지 않습니다. </li>
                                <li>공모주는 가격변동에 따라 투자원금의 일부 또는 전부 손실이 발생할 수 있으며, 투자손익은 전부 투자자 본인에게 귀속됩니다.</li>
                                <li>투자일임수수료는 투자일임계약서상 고객과의 합의에 의하며, 이외에도 거래수수료 및 청약수수료가 발생합니다. </li>
                                <li>공모주에 대한 청약의 권유는 주관 증권회사에서 제공하는 투자설명서에 따릅니다. </li>
                                <li>공모주  일반투자자에게는  균등배정방식과  비례배정방식이  적용되어 각 배정방식에 따라 공모주 배정결과가 다를 수 있습니다.</li>
                                <li>공모주는 통상 상장초기 가격 변동성이 크며, 상장 후 시가가 공모가를 하회할 경우 투자손실이 크게 발생할 수도 있습니다. </li>
                            </Ol>
                        </Text2>
                        <Text2>
                            ※ 엠엘투자자문(주)의 내부통제기준에 따라 준법감시 심의를 필하였음. <br />
                            - 엠엘광고준법 2024-제 21호(2024. 11. 1. ~ 2025. 10. 31.)
                        </Text2>
                    </InnerFrame>
                </MainFrame>
            </Frame>
        </>
    )
}